<template>
  <div class="quick-actions">
    <h5>Quick Actions</h5>
    <div class="qa-content">
      <div
        class="qa-add-user"
        v-b-tooltip.hover.left
        title="Add users and define their Permissions"
        @click="onAddUser"
        v-if="isAdmin"
      >
        <img src="@/assets/images/partner/home/add-users.svg" alt="add users" />
        <p>Add Users</p>
      </div>
      <div
        class="qa-launch"
        v-b-tooltip.hover.left
        title="Login into the learner platform and look how your courses are displaying."
        @click="launchLXP"
      >
        <img
          src="@/assets/images/partner/home/launch-lxp.svg"
          alt="launch lxp"
        />
        <p>Launch LXP</p>
      </div>
    </div>
    <add-user :show.sync="showAddUser" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddUser from "../Shared/AddUser.vue";
export default {
  components: {
    AddUser
  },
  data() {
    return {
      showAddUser: false
    };
  },
  computed: {
    ...mapGetters(["allConfig", "userInfo"]),
    isAdmin() {
      return this.userInfo.user.role === "admin";
    }
  },
  methods: {
    onAddUser() {
      this.showAddUser = true;
    },
    launchLXP() {
      window.open(this.allConfig.lxpBaseURL, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-actions {
  h5 {
    @include body-regular(500, $brand-neutral-900);
    margin-bottom: 8px;
  }
  .qa-content {
    background: $brand-neutral-0;
    border-radius: 16px;
    padding: 8px;
    gap: 8px;
    text-align: center;
    @include center;
    > div {
      padding: 8px 0px;
      cursor: pointer;
      &:hover {
        background: $brand-secondary1-50;
        border-radius: 16px;
        width: 81px;
      }
      p {
        @include label-large;
        margin: 8px 0 0;
      }
    }
  }
}
</style>
