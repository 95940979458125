<template>
  <div class="home-wrapper">
    <div class="hw__loader" v-if="isHomeLoading">
      <LxpLoader />
    </div>
    <div class="home-wrapper--div" v-else>
      <LxpAlert
        v-if="showUserAlert"
        variant="secondary1"
        :imgPath="
          require('@/assets/images/partner/home/celebration-success.svg')
        "
        :title="
          `Welcome to the ${userInfo.partner.org_short_name} Studio with WHO Academy`
        "
        sub-title="In this platform you will be able to list and manage the courses that will be visible to learners in the WHO Academy Learning Platform."
      ></LxpAlert>
      <div class="home-wrapper__main">
        <div class="home-wrapper__left">
          <div class="hwl__overview">
            <h5>
              Performance Overview
            </h5>
            <div class="hwl-overview__stats" v-if="usageStatistics">
              <div class="hwl-overview__stats--flex">
                <h4>{{ usageStatistics.total_courses_num }}</h4>
                <p>Listed courses</p>
              </div>
              <div class="hwl-overview__stats--flex">
                <h4>{{ usageStatistics.total_course_views_num }}</h4>
                <p>Total course views</p>
              </div>
              <div class="hwl-overview__stats--flex">
                <h4>{{ usageStatistics.total_course_redirects_num }}</h4>
                <p>Total course re-directs</p>
              </div>
            </div>
          </div>
          <course-data />
        </div>
        <div class="home-wrapper__right">
          <QuickActions />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpAlert, LxpLoader } from "didactica";

import CourseData from "./Home/CourseData.vue";
import QuickActions from "./Home/QuickActions.vue";
export default {
  components: { LxpAlert, LxpLoader, QuickActions, CourseData },
  data() {
    return {
      showUserAlert: false,
      usageStatistics: null,
      isHomeLoading: false
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isHomeLoading = true;
      this.$store.dispatch("listLatestCourses", { pageSize: 4 });
      this.$store.dispatch("getUsageStatistics").then(res => {
        this.usageStatistics = res;
        this.isHomeLoading = false;
      });
      this.checkUserStatus();
    },

    checkUserStatus() {
      let local_user_data = JSON.parse(
        sessionStorage.getItem("new_user_alert")
      );
      if (local_user_data === true) {
        this.showUserAlert = true;
        sessionStorage.setItem("new_user_alert", false);
      } else {
        this.showUserAlert = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  .home-wrapper--div {
    .hw__loader {
      height: 400px;
      @include flex-horizontal-center;
    }
    .home-wrapper__main {
      display: flex;
      h5 {
        @include body-regular(500, $brand-neutral-900);
        margin-bottom: 8px;
      }
      .home-wrapper__left {
        flex-grow: 1;
        .hwl__overview {
          margin-bottom: 24px;
          .hwl-overview__stats {
            display: flex;
            .hwl-overview__stats--flex {
              width: 251px;
              text-align: center;
              padding: 16px;
              background: $brand-neutral-0;
              border-radius: 16px;
              margin-right: 16px;
              flex: 1;
              @include center;
              &:last-child {
                margin-right: 0;
              }
              h4 {
                @include h4;
                margin-bottom: 4px;
              }
              p {
                margin-bottom: 0;
                @include body-regular(400, $brand-neutral-300);
              }
            }
          }
        }
      }
      .home-wrapper__right {
        margin-left: 24px;
        min-width: 130px;
      }
    }
  }
}
</style>
<style lang="scss">
.home-wrapper {
  .home-wrapper__main {
    .home-wrapper__left {
      .hwl__courses--list {
        @include course-card-hover;
      }
    }
  }
}
</style>
