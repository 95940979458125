<template>
  <div class="course-data__wrapper">
    <div class="cdw__empty" v-if="latestCourses.length === 0">
      <h5>Course Data</h5>
      <courses-api-request />
    </div>
    <div class="hwl__courses" v-else>
      <div class="hwl__courses--heading">
        <h5>Latest courses added</h5>
        <LxpButton variant="link-secondary1" @click="onViewAll"
          >View All</LxpButton
        >
      </div>
      <div class="hwl__courses--list">
        <LxpCourseCard
          :card-sub-title="course.org_short_name"
          :card-title="course.name"
          :card-image="
            course.course_image_url
              ? course.course_image_url
              : require('@/assets/images/partner/courses/thumbnail.png')
          "
          :default-image="
            require('@/assets/images/partner/courses/thumbnail.png')
          "
          footer-class="course__card--footer"
          v-for="(course, i) in latestCourses"
          :key="i"
        >
          <template #footer>
            <div class="course-card__footer">
              <div
                :class="[
                  'course-card__footer--div',
                  `course-card__${course.pacing}`
                ]"
              >
                <img
                  :src="
                    course.pacing === 'self'
                      ? require('@/assets/images/partner/courses/self-paced-icon.svg')
                      : require('@/assets/images/partner/courses/instructor-led-icon.svg')
                  "
                  alt="pacing"
                />
                <p class="mb-0">{{ pacing(course) }}</p>
              </div>
              <div class="course-card__footer--div course-card__language">
                <img
                  src="https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/language.svg"
                  alt="pacing"
                />
                <p class="mb-0">{{ course.language }}</p>
              </div>
            </div>
          </template>
        </LxpCourseCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LxpButton, LxpCourseCard } from "didactica";
import CoursesApiRequest from "../Shared/CoursesApiRequest.vue";
export default {
  components: {
    LxpButton,
    LxpCourseCard,
    CoursesApiRequest
  },
  computed: {
    ...mapGetters(["latestCourses"])
  },
  methods: {
    onViewAll() {
      this.$router.push({ name: "CourseList" });
    },
    pacing(course) {
      if (course.pacing === "self" || course.pacing === "instructor") {
        return course.pacing === "self" ? "Self-paced" : "Instructor-led";
      } else {
        return course.pacing;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.course-data__wrapper {
  .cdw__empty {
    h5 {
      @include body-regular(500, $brand-neutral-900);
      margin-bottom: 8px;
    }
  }
  .hwl__courses {
    .hwl__courses--heading {
      @include horizontal-space-between;
      margin-bottom: 8px;
    }
    .hwl__courses--list {
      display: flex;
      .lxp-course-card {
        width: 25%;
        @include course-card-footer;
      }
    }
  }
}
</style>
