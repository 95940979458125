<template>
  <div class="cdw__empty--content">
    <div class="cdw__empty--left">
      <h5>Get Course Data from your platform by making an API call</h5>
      <p>
        Would with go through the following steps to seamlessly integrate course
        information into studio from your application.
      </p>
    </div>
    <div class="cdw__empty--right">
      <LxpButton variant="secondary1" @click="getStarted"
        >Get Started</LxpButton
      >
    </div>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  methods: {
    getStarted() {
      this.$router.push({ name: "CourseList" });
    }
  }
};
</script>
<style lang="scss" scoped>
.cdw__empty--content {
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  @include horizontal-space-between;
  .cdw__empty--left {
    margin-right: 8px;
    p {
      margin-bottom: 0;
      @include label-large();
    }
    h5 {
      @include body-regular(500, $brand-neutral-900);
      margin-bottom: 8px;
    }
  }
  .cdw__empty--right {
    min-width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
