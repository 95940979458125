var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-data__wrapper"},[(_vm.latestCourses.length === 0)?_c('div',{staticClass:"cdw__empty"},[_c('h5',[_vm._v("Course Data")]),_c('courses-api-request')],1):_c('div',{staticClass:"hwl__courses"},[_c('div',{staticClass:"hwl__courses--heading"},[_c('h5',[_vm._v("Latest courses added")]),_c('LxpButton',{attrs:{"variant":"link-secondary1"},on:{"click":_vm.onViewAll}},[_vm._v("View All")])],1),_c('div',{staticClass:"hwl__courses--list"},_vm._l((_vm.latestCourses),function(course,i){return _c('LxpCourseCard',{key:i,attrs:{"card-sub-title":course.org_short_name,"card-title":course.name,"card-image":course.course_image_url
            ? course.course_image_url
            : require('@/assets/images/partner/courses/thumbnail.png'),"default-image":require('@/assets/images/partner/courses/thumbnail.png'),"footer-class":"course__card--footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"course-card__footer"},[_c('div',{class:[
                'course-card__footer--div',
                `course-card__${course.pacing}`
              ]},[_c('img',{attrs:{"src":course.pacing === 'self'
                    ? require('@/assets/images/partner/courses/self-paced-icon.svg')
                    : require('@/assets/images/partner/courses/instructor-led-icon.svg'),"alt":"pacing"}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.pacing(course)))])]),_c('div',{staticClass:"course-card__footer--div course-card__language"},[_c('img',{attrs:{"src":"https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/language.svg","alt":"pacing"}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(course.language))])])])]},proxy:true}],null,true)})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }